.intro-container {
  background: linear-gradient(55deg, $pink 0, $yellow 100%);
}

.intro-container-adnight {
  background: linear-gradient(55deg, $green 0, $blue 100%);
}

.intro-text {
  padding: 3rem;
  margin: 0 auto;
  max-width: 700px;
  text-align: center;

  @media (min-width: 768px) {
    padding: 4rem 3rem;
  }
}

.stoerer {

  position: absolute;
  right: 8%;
  top: 20%;

  background: black;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-15deg);
  padding: 10px;

  height: 225px;
  width: 225px;

  @media (max-width: 768px) {
    transform: rotate(-15deg) scale(0.8);
    top: 6%;
    right: 5%;
  }

  p {

    font-family: header-font,sans-serif;
    max-width: 230px;
    color: white;
    text-align: center;
    font-size: 16px;

    span.louder {
      font-size:32px;
      line-height:32px;
    }

}

}
.main {
    .a-container {
        .o-banner {
            .socialmedia {
                top: 75%;
                left:46%;
                position: absolute;
                width: 100vw;
                .socialmedia--facebook {float:left;padding-right:0.5em;}
                .socialmedia--instagram {float:left}
            }
             @media (max-width: 1024px) {
                 .socialmedia {
                     left:44%;
                 }
             }
             @media (max-width: 768px) {
                 .socialmedia {
                     left:42%;
                 }
             }
             @media (max-width: 640px) {
                 .socialmedia {
                     left:33%;
                 }
             }
        }
    }
}
