.footer {
  padding: 20px;

  p {
    margin: 0;
  }

  a {
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .wrap {
    display: flex;

    flex-direction: column;
    align-items: center;

    max-width: 1024px;
    margin: 0 auto;

    @media (min-width: 768px) {
      justify-content: space-between;
      flex-direction: row;
    }
  }
  .socialmedia {
      margin:0;padding:0.5em 0 0.5em 0;
      .socialmedia--facebook {float:left;margin:0;padding:0 0.5em 0 0;}
      .socialmedia--instagram {float:left;}
  }
}

.map-container {
  position: relative;

  img {
    width: 100%;
  }

  .map-wrapper {
    overflow: hidden;

    img {
      transform: scale(2);

      @media (min-width: 768px) {
        transform: scale(1);
      }
    }
  }

  .schraeglage {
    width: 150px;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      position: absolute;
      left: 20px;
      bottom: 0;
    }
  }
}
