//  --------    FONTS     --------


// normal text
$xs-font-size: 12px;
$s-font-size: 14px;
$m-font-size: 15px;
$l-font-size: 18px;
$xl-font-size: 20px;

$main-font-size: $m-font-size;
$main-font-color: #000;
$main-font: Cabin, Helvetica, Arial, sans-serif;
$main-font-weight: 400;
$main-bg-color: #fff;
$main-letter-spacing: 0.05em;

//header

$xs-header-font-size: 16px;
$s-header-font-size: 20px;
$m-header-font-size: 24px;
$l-header-font-size: 30px;
$xl-header-font-size: 36px;
$xxl-header-font-size: 36px;
$xxxl-header-font-size: 48px;

$header-font: header-font, sans-serif;
$header-letter-spacing: 0.05em;
$header-font-weight: $main-font-weight;


//links
$base-hover-color: #555;
$base-underline: 2px solid $base-hover-color;



//  --------    COLORS     --------

$black: #000;
$white: #fff;

$pink: #e82f86;
$yellow: #ffe72d;

$blue2: #5dc5ea;
$green: #b4c240;

$blue: #71c9f1;
$magenta: #e9408c;

@mixin linearGradient($top, $bottom){
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(55deg,  $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(55deg,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(55deg,  $top 0%,$bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(55deg,  $top 0%,$bottom 100%); /* IE10+ */
  background: linear-gradient(35deg,  $top 0%,$bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}


//  --------    SPACING     --------

// vertical spacing
$spacing-base: 4px;

$xxs-spacing: $spacing-base;
$xs-spacing: $spacing-base * 2;
$s-spacing: $spacing-base * 3;
$m-spacing: $spacing-base * 5;
$l-spacing: $spacing-base * 7;
$xl-spacing: $spacing-base * 10;
$xxl-spacing: $spacing-base * 14;

// horizontal spacing
$inline-base: 4px;

$xxs-spacing: $inline-base;
$xs-spacing: $inline-base * 2;
$s-spacing: $inline-base * 3;
$m-spacing: $inline-base * 5;
$l-spacing: $inline-base * 7;
$xl-spacing: $inline-base * 10;
$xxl-spacing: $inline-base * 14;


//  --------    ANIMATIONS     --------


@keyframes pulse {
    0% {
        text-shadow: 0 0 0  #07070759;
    }

    100% {
        text-shadow: 5px 10px 0 #41414154;
    }
}

.pulsing-text {
    text-shadow: 0 0 0  #07070759;
    transition: all 0.3s ease-out;


    &:hover {
        text-shadow: 5px 10px 0 #41414154;
        animation: pulse 0.7s alternate ;

    }
}
