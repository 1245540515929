BODY {
    overflow-x: hidden;
}

.default,
.thanks {
    .footer,
    .o-header {
        position: relative;

        background: linear-gradient(55deg, $green, $blue, $pink, $yellow);
        background-size: 400% 150%;
        animation: Gradient 20s ease infinite;
    }
}

.text.wrap {
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
}

.thanks {
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 3rem;
    }

    .text.wrap {
        padding-top: 0;
    }

    footer {
        margin-top: auto;
    }
}

.responsive-iframe {
    width: 100%;
    height: 0;
    position: relative;
    margin: 3% auto;
    padding-bottom: 43%;

    @media (min-width: 768px) {
        max-width: 600px;
        padding-bottom: 25%;
    }
}

#Anmeldung {
    padding-bottom: 0 !important;
    .form-image {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: right;

        .form-image--gwa {
            vertical-align: text-bottom;
            display: block;
            min-width: 100px;
            width: 225px;
            height: auto;
            float: right;
        }
    }
}
