.m-sponsors {
  padding: 0;
  width: 100vw;
  max-height: 600px;
  display: flex;
  background: linear-gradient(55deg, $green, $blue, $pink, $yellow);
  background-size: 400% 150%;
  //animation: Gradient 20s ease infinite;
  flex-direction: column;
  overflow: hidden;
  flex-flow: row nowrap;

  .slider__contents {
    flex-direction: column;
  }

  .a-keyvisual {
    min-width: 100%;
    text-align: center;
    height: 7vh;
  }

  .a-keyvisual:first-child {
    padding-top: 10vh;
  }

  .svg {
    width: 100vw;
    display: flex;
    flex-direction: row;
  }

  .svg-bg * {
    width: 95vw;
  }

  .svg-left,
  .svg-right {
    height: auto;
    width: 20vw;
  }

  .sponsor-info {
    width: 100vw;
    min-height: 350px;
    color: #fff;
    padding: $xl-spacing;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;

    p {
      width: 100%;
      color: $white;
    }

    a {
      color: $white;
      text-decoration: none;
      border-bottom: 2px solid $white;
      max-width: fit-content;

      &:hover {
        color: $blue;
        border-color: $blue;
      }
    }

    &::after {
      content: "";
      width: 30px;
      height: 100%;
    }
  }

  .slider__nav:checked {
    animation: check-colored 0.5s linear forwards;
  }
}

@media (min-width: 768px) {
  .m-sponsors {
    .sponsor-info {
      min-height: 250px;
    }
  }
}

@media (min-width: 1224px) {
  .m-sponsors {
    flex-direction: row;

    .slider__contents {
      width: 100vw;
      flex-direction: row;
    }

    .sponsor-info {
      width: calc(50vw + 80px);
      justify-content: center;

      p {
        max-width: 800px;
      }
    }

    .svg {
      width: 50vw;
      height: 605px;
      display: flex;
      flex-direction: column;
      border-right: 2px solid #000;

      .svg-bg * {
        width: 50vw;
      }
    }

    .svg-left,
    .svg-right {
      height: 100%;
      width: auto;
    }
  }
}
