.scroll-hint {
  color: $white;
  left: 80px;
  position: relative;

  &::before {
    content: "\21E8";
    color: $white;
    margin-right: $s-spacing;
  }
}

@media (min-width: 768px) {
  .scroll-hint {
    display: none;
  }
}
