.a-keyvisual {
  font-size: $xxl-header-font-size;
  color: $white;
  padding: 1rem;

  &--calendar {
    font-family: calendar-font, sans-serif;
    font-size: $xxxl-header-font-size;
    letter-spacing: 0.01em;
    animation-name: fadeInRight;
  }

  &--sweet {
    font-family: sweet-font, sans-serif;
    font-size: 20px;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 26px;
    }
  }
}

.a-eyecatcher {
  height: 150px;
  width: 150px;
  background-color: $black;
  border-radius: 51%;
  display: inline-block;
  padding: $l-spacing;
  text-align: center;
  z-index: 1;

  h4 {
    transform: rotate(-15deg);
    color: $white;
    font-size: $xs-header-font-size;
  }
}

.a-container {
  width: 100vw;
  max-width: 100vw;
  padding: 5vh 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--white {
    background: $white;
  }

  &--pink {
    @include linearGradient($pink, $yellow);
  }

  &--green {
    @include linearGradient($green, $blue2);
  }

  &--blue {
    @include linearGradient($blue, $magenta);
  }

  &--black {
    background: $black;
  }

  &--animated {
    background: linear-gradient(55deg, $green, $blue2, $pink, $yellow);
    background-size: 400% 150%;
    //animation: Gradient 20s ease infinite;

    &--adnight {
      background: linear-gradient(55deg, $pink, $yellow, $green, $blue2);
      background-size: 400% 150%;
      //animation: Gradient 20s ease infinite;
    }
  }
}

.a-open-button {
  height: $xl-spacing;
  width: $xl-spacing;
  border: $xxs-spacing solid $white;
  border-radius: $l-spacing;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-self: flex-end;
  flex-direction: column;
  position: relative;

  span {
    font-size: $m-header-font-size;
    color: $white;
    background: url("../images/plus.svg");
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    height: 41px;
  }
}
//  --------    TABLET (portrait and landscape)     --------

@media (min-width: 768px) {

}


//  --------    DESKTOP     --------

@media (min-width: 1224px) {

}
