.hbspt-form {
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

form {
  max-width: 700px;
  align-self: center;
  color: $white;
  font-weight: 300;
  padding: 0 $m-spacing;

  .hs-fieldtype-select,
  .hs-fieldtype-text {
    .hs-field-desc,
    label span {
      //description text
      display: inline-block;
      padding: $s-spacing 0;
      font-family: $header-font;
      letter-spacing: $header-letter-spacing;
      font-weight: $header-font-weight;
      line-height: $m-header-font-size;
      font-size: $m-header-font-size;
    }

    // Text Fields
    input[type="text"],
    input[type="email"] {
      border-radius: 0;
      line-height: $m-header-font-size;
      background-color: transparent;
      border: 3px solid $white;
      padding: $s-spacing;
      margin-bottom: $m-spacing;
      width: 100%;
      box-sizing: border-box;
      outline: 0;
      color: $white;
      font-size: $l-font-size;
      font-weight: 300;
      letter-spacing: $main-letter-spacing;
      font-family: $main-font;
    }

    // Dropdown
    .hs-input {
      font-family: $main-font;
      font-weight: $main-font-weight;
      background-color: transparent;
      color: $white;
      border: 3px solid #fff;
      font-size: inherit;
      padding: $xs-spacing $xl-spacing $xs-spacing $xs-spacing;
      margin: 0;
      border-radius: 0;
      text-indent: 0.01px;
      text-overflow: '';
      -webkit-appearance: button;
    }
  }

  // Checkbox
  .inputs-list {
    list-style: none;
    padding: 0;

    input[type="checkbox"] {
      vertical-align: text-bottom;
    }

    span {
      padding-left: $s-spacing;
    }
  }

  .hs-fieldtype-select {
    margin: $m-spacing 0;

    .input {
      &::after {
        /* dropdown arrow */
        pointer-events: none;
        content: "\25BC";
        font-size: 12px;
        line-height: 1;
        top: 50%;
        left: -$xl-spacing;
        height: 8px;
        width: 18px;
        padding: 12px 10px 9px;
        position: relative;
        border-left: 3px solid #fff;
      }
    }
  }

  .hs-richtext {
    padding: $s-spacing 0;
    font-style: italic;
  }
  // Button
  .hs_submit {
    margin: $m-spacing 0;

    input[type="submit"] {
      -webkit-appearance: none;
      background: transparent;
      border: 3px solid $white;
      color: $white;
      padding: $xs-spacing $m-spacing;
      font-family: $header-font;
      font-weight: $header-font-weight;
      letter-spacing: $header-letter-spacing;
      font-size: $s-header-font-size;
    }
  }
}
