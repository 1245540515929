.m-schedule {
  display: inline-block;
  padding-top: $xxl-spacing;
  width: 50vw;
  margin: 0 auto;
  list-style: none;

  .schedule-event {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    margin: $s-spacing 0;
    padding: $l-spacing;
    background: #ffffff36;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;

      &:first-child {
        font-weight: bold;
      }
    }

    &.is-break {
      background: repeating-linear-gradient(135deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) , rgb(255, 255, 255) 4px, rgba(255, 255, 255, 0) 5px, rgba(255, 255, 255, 0) 15px);
    }
  }

  .schedule-heading {
    color: $white;
  }
}
