.m-timetable {
  border-collapse: collapse;
  table-layout: fixed;
  display: flex;
  justify-content: center;
  margin: 0 $s-spacing $xxl-spacing 0;

  tbody {
    overflow: scroll;
  }

  tr {
    color: $white;
  }

  td {
    color: $black;
    text-align: left;
    padding-left: $l-spacing;
    font-size: $l-font-size;
    line-height: $m-header-font-size;
    border: 2px solid $white;
    width: 10vw;
    background: #ffffff36;

    .location {
      font-size: $s-font-size;
      line-height: $xl-header-font-size;
      font-style: italic;
      color: $white;
    }
  }

  td[colspan="5"] {
    background: $white;
    color: $green;
    text-align: center;
  }
}
