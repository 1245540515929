//https://codepen.io/tari/pen/KzzzwX

$slider-length: 4;
$radioBtn-size: 12px;
$btn-color: $white;
$active-color: $black;
$active-color-colored: $blue;
//---------------------------------------------------

*, *:before, *:after { box-sizing: border-box;}


.slider {
    height: 100vh;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    justify-content: center;

    &__nav {
        width: $radioBtn-size;
        height: $radioBtn-size;
        margin: 2rem $radioBtn-size;
        border-radius: 50%;
        z-index: 10;
        outline: $radioBtn-size / 2 solid $btn-color;
        outline-offset: $radioBtn-size / -2;
        box-shadow:
            0 0 0 0 $active-color,
            0 0 0 0 rgba($active-color,0);
        cursor: pointer;
        appearance: none;
        backface-visibility: hidden;

        &:checked {
            animation: check 0.5s linear forwards;

            @for $i from 0 to $slider-length {
                &:nth-of-type(#{$i+1}) {
                    ~ .slider__inner {
                        transform: translateX((-100% * $i) / $slider-length);
                    }
                }
            }
        }
    }

    &__inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% * $slider-length;
        height: 100%;
        transition: all 1s ease-out;
        display: flex;
        flex-flow: row nowrap;
    }

    &__contents {
        height: 100%;
        display: flex;
    }
}



// animation ---------------------------

@keyframes check {
    50% {
        outline-color: $active-color;
        box-shadow:
            0 0 0 $radioBtn-size $active-color,
            0 0 0 $radioBtn-size*3 rgba($active-color,0.2);
    }
    100% {
        outline-color: $active-color;
        box-shadow:
            0 0 0 0 $active-color,
            0 0 0 0 rgba($active-color,0);
    }
}

@keyframes check-colored {
    50% {
        outline-color: $active-color-colored;
        box-shadow:
            0 0 0 $radioBtn-size $active-color-colored,
            0 0 0 $radioBtn-size*3 rgba($active-color-colored,0.2);
    }
    100% {
        outline-color: $active-color-colored;
        box-shadow:
            0 0 0 0 $active-color-colored,
            0 0 0 0 rgba($active-color-colored,0);
    }
}
