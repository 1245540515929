body {
  //padding: 10vh 5vw;
  height: 100vh;
  font-family: $main-font;
  font-weight: $main-font-weight;
  font-size: $main-font-size;
  letter-spacing: $main-letter-spacing;
  color: $main-font-color;
  background: $main-bg-color;
  margin: 0;
  line-height: 1.4;
}

p {
  font-family: $main-font;
  font-size: $main-font-size;
  font-weight: $main-font-weight;
  letter-spacing: $main-letter-spacing;
  color: $main-font-color;
}

h1,
h2,
h3,
h4 {
  margin: 0 auto;
  font-family: $header-font;
  letter-spacing: $header-letter-spacing;
  font-weight: $header-font-weight;
}

.h1 {
  font-size: 5vh;
  margin: 2.5vh auto;

  &.white {
    color: $white;
  }
}

.link {
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid $white;
  padding-bottom: 3px;
  font-style: italic;
}

.main {
  overflow: hidden;
}

.button {
  border: 2px solid white;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  padding: 10px 20px;

  &:hover {
    background: white;
    color: black;
    transition: all .5s ease;
  }
}

img {
  max-width: 100%;
}
