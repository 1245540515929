.jobs-container{
  background: #e5007e;
  //background-size: 400% 150%;
  padding-bottom: 2rem;

  .content-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > * {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .text-container {
      max-width: 400px;
      padding: 1rem;
      margin: 0 auto;
    }

    p {
      color: white;
    }

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    h3 {
      font-size: 1.5rem;
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .a-keyvisual--sweet {
    font-size: 28px;
  }

  @media (min-width: 768px) {
    padding-bottom: 0;

    .swiper-wrapper {
      padding-bottom: 0;
    }
  }
}

.job-img {
  width: 100vw;
  opacity: .9;
  background: center no-repeat #e5007e;
  background-blend-mode: lighten;
  background-size: cover;
  display: inline-block;
  padding-bottom: 20rem;

  @media (min-width: 768px) {
    width: 60vw;
    height: 100%;

    padding-bottom: 42rem;
  }
}
