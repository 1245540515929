@font-face {
  font-family: "header-font";
  src: url("../fonts/built-titling-sb.ttf") format("truetype");
}

@font-face {
  font-family: "calendar-font";
  src: url("../fonts/calendar-note.ttf") format("truetype");
}

@font-face {
  font-family: "sweet-font";
  src: url("../fonts/sweet-sorrow.otf") format("opentype");
}
