.o-header{
  padding: 14px 24px;
  width: 100%;

  position: absolute;
  z-index: 100;

  &.fix {
    position: fixed;

    background: linear-gradient(55deg, $green, $blue, $pink, $yellow);
    background-size: 400% 150%;
    animation: Gradient 20s ease infinite;

    z-index: 1000;
  }

  img {
    height: 50px;
    width: auto;
  }

  @media (min-width: 768px) {
    padding: 20px 30px;

    img {
      height: 90px;
    }
  }
}

.m-nav-bar {
  //display: none;

  .navigation {
    list-style: none;
    position: absolute;
    background: $black;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    //padding: $xl-spacing 0;
    text-align: center;
    margin: 0;

    @media (max-width: 768px) {
      max-height: 0;
      overflow: hidden;

      padding: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      transition: max-height .5s ease;
    }

    .nav-item {
      a {
        padding: $xs-spacing;
        display: block;
        font-size: 3rem;
        font-family: $header-font;
        font-weight: $header-font-weight;
        letter-spacing: $header-letter-spacing;
        text-decoration: none;
        color: $white;
        border-bottom: 2px solid transparent;

        @media (min-width: 768px) {
          font-size: 2rem;

          &:active,
          &:hover {
            color: black;
            border-bottom: 2px solid black;
          }
        }
      }
    }
  }

  .burger {
    width: 40px;
    align-items: center;
    z-index: 2;
    height: 40px;
    fill: $white;
    position: absolute;
    display: flex;
    cursor: pointer;
    right: $l-spacing;
    top: $l-spacing;

    .visually-hidden {
      position: absolute!important;
      clip: rect(1px,1px,1px,1px);
      padding: 0!important !important;
      border: 0!important;
      height: 1px!important;
      width: 1px!important;
      overflow: hidden;
    }

    .layer {
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      height: 3px;
      background: $white;
      border-radius: 1px;
      transition: 150ms all;

      &:first-child {
        top: 0;
      }

      &.layer:nth-child(2),
      .layer:nth-child(3) {
        top: 10px;
      }

      .nav {
        bottom: 0;
      }
    }
  }
}

.m-nav-bar.active {
  .navigation {
    max-height: 999px;
    transition: max-height .5s ease;
  }
}


@media (min-width: 768px) {
  .m-nav-bar {
    display: block;

    .burger {
      display: none;
    }

    .navigation {
      display: flex;
      position: relative;
      background: none;
      text-align: right;
      height: auto;
      width: auto;
      padding: 0;
      left: auto;
      top: auto;
      justify-content: flex-end;

      .nav-item {
        a {
          color: $main-font-color;
        }
      }
    }
  }
}
