.timetable {
  color: white;

  h2 {
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 5px 0;
  }

  .time-block {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 0 20px;
  }

  table {
    max-width: 600px;
    margin: 10px;
  }

  tr td {
    padding-bottom: 2rem;
  }

  tr td:first-of-type {
    font-family: header-font,sans-serif;
    width: 100px;
    font-size: 1.5rem;
  }

  tr td:not(:first-of-type) {
    padding-left: 20px;
    font-weight: lighter;
  }

  .button {
    margin: 1rem auto 2rem;
  }

  p {
    color: white;
  }
}


// dirty mobile / desktop fix

.no-mobile {
  display: none;
}

@media (min-width: 768px) {
  .time-container {
    display: flex;
    margin: 0 auto;
    padding: 2rem 0;
  }

  .timetable .time-block {
    margin: 0;
  }

  .mobile {
    display: none;
  }

  .no-mobile {
    display: block;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
