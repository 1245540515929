.m-card {
  min-width: 100vw;
  min-height: 40vh;
  padding: $xl-spacing 0;
  text-align: center;
  margin: 0;
  flex: 1;
  display: flex;
  justify-content: center;
  vertical-align: baseline;
  flex-direction: column;
  cursor: pointer;

  * {
    pointer-events: none;
  }

  &--white {
    background: $white;

    .a-keyvisual {
      color: $black;
    }
  }

  &--pink {
    @include linearGradient($pink, $yellow);
  }

  &--green {
    @include linearGradient($green, $blue);
  }

  &--blue {
    @include linearGradient($blue, $pink);
  }

  p {
    padding: $m-spacing $xl-spacing;
    color: $white;
    font-size: $l-font-size;
    line-height: $l-header-font-size;
    max-width: 600px;
    margin: 0 auto;
  }

  .answer {
    height: 100%;
    width: 100%;
    display: flex;
    visibility: hidden;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    background: $white;
    pointer-events: none;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.3);
    animation-name: bounceOut;
    transition: all 0.7s ease-out;

    .a-keyvisual {
      color: $black;
    }
  }

  .a-open-button {
    pointer-events: none;
    position: absolute;
    right: $l-spacing;
    bottom: $l-spacing;
  }

  &.flipped {
    .answer {
      visibility: visible;
      backface-visibility: visible;
      animation-name: bounceIn;
    }

    .a-open-button {
      border-color: $black;

      span {
        background: url("../images/minus.svg");
        background-size: 40%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        height: 41px;
        color: $black;
      }
    }
  }

  &:hover {
    .a-keyvisual,
    .a-open-button {
      text-shadow: 5px 10px 0 #41414154;
      animation: pulse 0.7s alternate;
    }

    .answer {
      .a-keyvisual {
        text-shadow: none;
        animation: none;
      }
    }
  }
}

@media (min-width: 768px) {
  .m-card {
    min-width: 30vw;
    min-height: 35vh;
    flex: 1;
    margin: 0;
  }
}

@media (min-width: 768px) {
  .m-card {
    .a-open-button {
      bottom: $xl-spacing;
    }
  }
}
