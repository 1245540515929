
.o-card-grid {
    display:flex;
    flex-wrap: wrap;
    align-content: flex-start;
    position: relative;
    justify-content: space-between;
    min-height: max-content;

    .m-card {
        position: relative;
        margin: 0 0 3vh 0;
    }
}

.o-banner {
    //@include linearGradient($pink, $yellow);
    height: 80vh;
    padding: $xl-spacing;
    display: flex;
    align-items: center;

    &.animated {
        background: linear-gradient(55deg, $green, $blue, $pink, $yellow);
        background-size: 400% 150%;
        animation: Gradient 20s ease infinite;
    }

    &.bw-animated {
        background: linear-gradient(55deg, $black, $white);
        background-size: 400% 100%;
        animation: Gradient 2s ease infinite;
    }


.a-logo {
        display: block;
        margin: 0 auto;
        width: 50vw;
        height: auto;
        padding-top: 2em;
    }
}

.o-week-schedule {
    display: flex;
    flex-direction: row;
    width: 95vw;
    margin: 0 auto;
    flex-wrap: wrap;

    .m-schedule {
        flex: 1;
        min-width: 50vw;
        padding-left: 0;
    }
}

.o-job-slider {
    width: 100vw;
    height: 90vh;
    display: flex;
    background: none;

    .job-img {
        width: 100vw;
        height: 100%;
        opacity: 0.9;
        background: $pink;
        background-position: center;
        background-blend-mode: lighten;
        background-repeat: no-repeat;
        background-size: cover;
        left: 0;
        display: inline-block;
    }

    .job-description {
        height: 100%;
        background: $pink;
        color: $white;
        padding: $xl-spacing;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100vw;

    }

    .slider__contents {
        flex-direction: column;
    }

}


@-webkit-keyframes Gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

//  --------    TABLET (portrait and landscape)     --------

@media (min-width: 768px) {

    .o-banner {
        margin: 0 $s-spacing;

        .a-logo {
            width: 25vw;
        }

    }

    .o-card-grid {

        padding: 0.5vw;

        .m-card {
            min-width: 48vw;
            max-width: 48vw;
            min-height: 48vw;

            &:first-child {
                z-index: 2;

                animation-name: fadeInLeft;
            }

            &:nth-child(2) {
                z-index: 1;
                animation-delay: 0.4s;
                animation-name: fadeInRight;
            }

            &:nth-child(3) {
                z-index: 3;
                animation-delay: 0.8s;
                animation-name: fadeInLeft;
            }
            &:nth-child(4) {
                z-index: 4;
                animation-delay: 1.2s;
                animation-name: fadeInRight;
            }

            &:nth-child(5) {
                animation-delay: 1.6s;
                animation-name: fadeInUp;
            }

            &:nth-child(6) {
                animation-delay: 1.8s;
            }

            &:nth-last-child {
                animation-name: fadeInUp;
            }
        }
    }

    .o-header {
        display: flex;
        align-items: center;
        padding: 0 $s-spacing;

        .a-logo {
            width: 10vw;
        }

        .m-nav-bar {
            width: 90vw;
        }
    }

    .o-job-slider {

        .job-description {
            right: $m-spacing;
        }
    }

}


//  --------    DESKTOP     --------

@media (min-width: 1224px) {

    .o-card-grid {

        padding: 1vw;

        .m-card {
            min-width: 48.5vw;
            max-width: 48.5vw;
            min-height: 25vw;

            &:nth-child(2) {
            }

            &:nth-child(3) {

            }
            &:nth-child(4) {
            }

            &:nth-child(5) {
            }

            &:nth-child(6) {
            }
        }
    }

    .o-week-schedule {
        width: 90vw;

        .m-schedule {
            width: auto;
            min-width: 300px;
            padding-left: $xl-spacing;
        }
    }

    .o-job-slider {

        .job-img {
            width: 70vw;
        }

        .job-description {
            width: 30vw;
            text-align: left;
        }

        .slider__contents {
            flex-direction: row;
        }
    }
}
