.agencies-container{
  background: linear-gradient(55deg, $pink 0, $yellow 100%);
  //background-size: 400% 150%;
  padding-bottom: 2rem;
  padding-top: 2rem;

  .content-container {
    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > * {
      min-height: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      max-width: 500px;
      margin: 0 auto;

      padding: 1rem;
    }

    img {
      max-height: 200px;
    }

    a {
      color: black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    h3 {
      font-size: 1.5rem;
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .swiper-wrapper {
    padding-bottom: 2rem;
  }
}

.swiper-wrapper {
  min-height: 100%;
  padding-bottom: 4rem;
}

.swiper-slide {
  //height: auto !important;
}
